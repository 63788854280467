import React from "react";
const Signin = React.lazy(() => import("./Authentication/Signin"));
const AllPrograms = React.lazy(() => import("./All Programs/index"));
const MyCourses = React.lazy(() => import("./My Courses/index"));
const Resume = React.lazy(() => import("./Resume/index"));
const ProfileSetting = React.lazy(() => import("./ProfileSetting/index"));
const CardDashboard = React.lazy(() => import("./MyCardDashboard/index"));
const Lms = React.lazy(() => import("./Lms/index"));
const Lms_V1 = React.lazy(() => import("./Lms/index_v1"));
const Challenge = React.lazy(() => import("./Challenge/index"));
const Internships = React.lazy(() => import("./Internships/index"));
const Certificate = React.lazy(() => import("./Certificates/index"));
const PaidInternship = React.lazy(() => import("./PaidInternship/index"));
const ProProgram = React.lazy(() => import("./ProProgram/index"));
const PaymentModal = React.lazy(() => import("./PaymentModal/index"));
const Track = React.lazy(() => import("./Track/index"));
const Policy = React.lazy(() => import("./Policy/index"));
const PaymentPage = React.lazy(() => import("./PaymentPage/index"));
const ReferAndEarn = React.lazy(() => import("./ReferAndEarn"));
const Quiz = React.lazy(()=>import("./Quiz/index"))
const AiEvaluation = React.lazy(()=>import("./AIEvaluation/index"))

export {
 Signin,
 AllPrograms,
 MyCourses,
 Resume,
 ProfileSetting,
 CardDashboard,
 Lms,
 Challenge,
 Internships,
 Certificate,
 PaidInternship,
 ProProgram,
 PaymentModal,
 Track,
 Policy,
 PaymentPage,
 Lms_V1,
 ReferAndEarn,
 Quiz,
 AiEvaluation
};

// Creating Key value pair for Program Dashboard

export const ProgramDashboard = {
"RESUME" : Resume,
"LMS_HYBRID":Lms,
"LSM_LIVE":Lms_V1,
"CHALLENGE":Challenge,
"INTERNSHIP" : Internships,
"INTERNSHIP_PAID":PaidInternship,
"QUIZ":Quiz,
"AiEvaluation":AiEvaluation
}