import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

interface ResponseData {
  audio: string; // Base64 encoded audio content
}

const TextToSpeech: React.FC = () => {
  const [text, setText] = useState<string>('');
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // Handle text input change
  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  // Convert text to speech
  const handleConvertToSpeech = async () => {
    if (!text.trim()) {
      alert('Please enter some text');
      return;
    }

    setLoading(true);
    setError('');
    try {
      const response = await axios.post<ResponseData>('http://localhost:5001/api/v1/eval/synthesizeAudio', 
        { text },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.audio) {
        // Convert base64 audio string to Blob and generate a URL
        const audioBlob = new Blob([new Uint8Array(atob(response.data.audio).split('').map((char) => char.charCodeAt(0)))], { type: 'audio/mp3' });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
      }
    } catch (err) {
      setError('Error generating speech');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  // Play the audio automatically once the URL is set
  useEffect(() => {
    if (audioUrl && audioRef.current) {
      audioRef.current.play();
    }
  }, [audioUrl]);

  // Replay the audio
  const handleReplay = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
  };

  return (
    <div className="text-to-speech-container">
      <h1>Text-to-Speech Demo</h1>
      <textarea
        value={text}
        onChange={handleTextChange}
        placeholder="Enter text to convert to speech"
        rows={5}
        cols={50}
      />
      <br />
      <button onClick={handleConvertToSpeech} disabled={loading}>
        {loading ? 'Converting...' : 'Convert to Speech'}
      </button>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {audioUrl && (
        <div>
          <button onClick={handleReplay}>Replay</button>
          <audio ref={audioRef} style={{ display: 'none' }}>
            <source src={audioUrl} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        </div>
      )}
    </div>
  );
};

export default TextToSpeech;
