import logo from './svg/logo.svg'
import logo12thPass from "./images/12thPass.co.png"
import dashboard from './icons/dashboard.svg'
import signinBackground from './images/pageVisual.jpg'
import Google from './svg/googleLogo.svg'
import backButton from './svg/backBtnImg.svg'
import WhatsApp from './images/whatsappIcon.png'
import avatar1 from './svg/avatar1.svg'
import avatar2 from './svg/avatar2.svg'
import avatar3 from './svg/avatar3.svg'
import avatar4 from './svg/avatar4.svg'
import homePerson1 from './images/Student 1.png'
import homePerson2 from './images/Student 2.png'
import homePerson3 from './images/Student 3.png'
import homePerson4 from './images/Student 4.png'
import Blunt from './images/Company/blunt.png'
import CSB from './images/Company/Chai Sutta Bar_out 2.png'
import Cultfit from './images/Company/CultFit.png'
import Decathlon from './images/Company/Decathlon.png'
import NTL from './images/Company/ntl.png'
import Kifd from './images/Company/KFID.png'
import Motiongility from './images/Company/MotionGility.png'
import Podpitara from './images/Company/PodPitara.png'
import Practo from './images/Company/Practo.png'
import Stage from './images/Company/Stage.png'
import Snapdeal from './images/Company/Snapdeal.png'
import Uber from './images/Company/Uber.png'
import upGrad from './images/Company/upGrad.png'
import Urban from './images/Company/Urban Kisaan.png'
import Supertail from './images/Company/Supertails.png'
import tapOn from './images/Company/TAP ONN.png'
import Handshake from './Gif/Handshake.gif'
import dm from './Gif/DM.gif'
import gdPro from './Gif/GDPRO.gif'
import pro from './svg/pro.svg'
import arrow from './svg/arrow.svg'
import SMM from './images/courses/smm.png'
import GD from './images/courses/graphic_designing.png'
import CW from './images/courses/content_writing.png'
import VE from './images/courses/video_editing.png'
import PSY from './images/courses/psychology.png'
import WD from './images/courses/webdev.png'
import FM from './images/courses/finance.png'
import More from './images/courses/MoreCoruses.png'
import LMS from './svg/LMS.svg'
import Resume from './svg/Resume.svg'
import Challenge from './svg/Challenge.svg'
import Internship from './svg/Internship.svg'
import Choithram from './images/schools/choitam.png'
import Dps from './images/schools/dps.png'
import Emerald from './images/schools/emrald.png'
import GdGoenka from './images/schools/goenka.png'
import Khaitan from './images/schools/khaitan.png'
import Maurya from './images/schools/maurya.png'
import SSV from './images/schools/ssv.png'
import Sarvodaya from './images/schools/sarvodaya.png'
import CertificatePro from './images/New Certificate.png'
import Question from './Gif/Question.gif'
import Study from './icons/Study Abroad.png'
import StudySvg from './svg/studyAbroad.svg'
import Global from './icons/Global.png'
import Saving from './icons/Savings.png'
import University from './icons/Universities.png'
import Card1 from './icons/Card1.png'
import Link from './icons/Link.png'
import Premium from './icons/Premium.png'
import Work from './icons/Work.png'
import YouvahCard from './svg/Card.svg'
import ResumeGif from './Gif/Resume.gif'
import Varnitha from './images/testimonials/Varnitha Patcha.png'
import Avika from './images/testimonials/Avika Gangrade.png'
import Ashvika from './images/testimonials/Ashvika Nair.png'
import Divy from './images/testimonials/Divy Goyal.png'
import Japji from './images/testimonials/Japji Kaur Ghai.png'
import Milan from './images/testimonials/Milan Mukati.png'
import Pratyush from './images/testimonials/Pratyush Sharan.png'
import Radhika from './images/testimonials/Radhika Gupta.png'
import Sanskriti from './images/testimonials/Sanskriti Gupta.png'
import IIM from './images/IIM.png'
import Linked from './svg/Linkedin.svg'
import Instagram from './svg/Instagram.svg'
import Facebook from './svg/Facebook.svg'
import Sidebar_Toogle from './svg/Sidebartoogle.svg'
import Master_desktop from './images/Masterunion.png'
import Tick from './svg/tick.svg'
import Cross from './svg/cross.svg'
import Awaits from './svg/awaits.svg'
import Crown from './svg/crown.svg'
import AllprogramBanner from './svg/Banner.svg'
import AllprogramBanner_phone from './images/banner_phone.png'
import DM from './images/courses/DM.png'
import GDPRO from './images/courses/GDpro.png'
import Master_phone from './images/Masterunion_phone.png'
import lock from './icons/lock.png'
import unlock from './icons/icons8-unlock-64.png'
import check from './icons/icons8-tick-64.png'
import dot from './icons/dot.png'
import trophy from './icons/trophy.svg'
import Cry from './images/cry.png'
import Stars from './images/stars (2).png'
import Availability from './svg/availability.svg'
import Buffer from './images/Buffer.png'
import Pika from './Gif/pika.gif'
import No_Course from './Gif/nocourse.gif'
import Dude from './svg/Dude.svg'
import EllipseB from './svg/EllipseB.svg'
import Sun from './images/New sun.png'
import Book from './images/Book.png'
import SunCloud from './images/Sun.png'
import Colon from './svg/colon.svg'
import Briefcase from './icons/briefcase.png'
import Bullseve from './icons/Bullseye.png'
import Bullseye from './icons/Bullseye.png'
import Check from './icons/check.png'
import Enroll from './icons/enroll.png'
import Master from './icons/master.png'
import Party from './icons/party.png'
import StarNew from './images/stars.png'
import Level from './svg/level.svg'
import Watermelon from './svg/watermelon.svg'
import Edit from './icons/edit.png'
import LogoStrokes from './images/LogoStrokes.png'
import ChetanSirSign from './images/sign.png'
import BadgeInternship from './images/badgeInternshi.png'
import CertificateTrophy from './images/Trophy.png'
import LogoWithoutName from './images/LogoRocket.png'
import WhatsAppGif from './Gif/whatsapp.gif'
import Drag from './icons/icons8-dots-60.png'
import IG from './icons/instagram.svg'
import FB from './icons/facebook.svg'
import Snap from './icons/snapchat.svg'
import x from './icons/icons8-twitterx-50.png'
import linkedin from './icons/linkedin.svg'
import Email from './icons/gmail.svg'
import Phone from './icons/Phone.svg'
import Dob from './icons/Calendar.svg'
import School from './icons/school.svg'
import Link1 from './icons/link.svg'
import SocialMedia from './icons/SocialMedia.svg'
import Quality from './Gif/Quality.gif'
import Access from './Gif/Accessablity.gif'
import Impact from './Gif/impact.gif'
import News1 from './images/News1.png'
import News2 from './images/News2.png'
import News3 from './images/News3.png'
import SPOTL from './images/Spotl.png'
import SPOTR from './images/spotr.png'
import Chetan from './images/Team/Chetan.png'
import Raghav from './images/Team/Raghav.png'
import Rohit from './images/Team/Rohit.png'
import Devesh from './images/Team/Devesh.png'
import Yatharth from './images/Team/yatharth.png'
import Parv from './images/Team/Parv.png'
import Sakshi from './images/Team/sakshi.png'
import Akshay from './images/Team/akshay.png'
import Anshul from './images/Team/anshul.png'
import Kuldeep from './images/Team/kuldeep.png'
import Shyam from './images/Team/shyam.png'
import Slide1 from './images/slide1.png'
import Slide2 from './images/slide2.png'
import Slide3 from './images/slide3.png'
import Slide4 from './images/slide4.png'
import Slide5 from './images/slide5.png'
import Slide6 from './images/slide6.png'
import Slide7 from './images/slide7.png'
import Thumbnail1 from './images/Thumbnail1.png'
import Thumbnail2 from './images/Thumbnail2.png'
import JobCompany1 from './images/Job/36Mr.Digito-White.png'
import JobCompany2 from './images/Job/Creative-Blast.png'
import JobCompany3 from './images/Job/GraffersID.png'
import JobCompany4 from './images/Job/Pixxzzoo.png'
import JobCompany5 from './images/Job/WOWIT.png'
import JobCompany6 from './images/Job/graphic-360.png'
import JobCompany7 from './images/Job/lasso art.png'
import JobCompany8 from './images/Job/Group 30282.png'
import Hubspot from "./svg/Hubspot.svg"
import GA from "./svg/googleAnalytics.svg"
import Canva from "./svg/canva.svg"
import Mailchimp from "./svg/MailChimp.svg"
import Hootsuite from "./svg/Hootsuite.svg"
import Semrush from "./svg/Semrush.svg"
import Hotjar from "./svg/Hotjar.svg"
import Ahrefs from "./svg/Ahrefs.svg"
import Buzzsumo from "./svg/Buzzsumo.svg"
import BufferSvg from "./svg/Buffer.svg"
import FitPro from "./svg/FitPro.svg"
import TheYogaClub from "./svg/theYogaClub.svg"
import Oic from "./svg/oic.svg"
import YouvahCertPng from "./images/youvahCert.png"
import YouvahCertSvg from "./svg/youvahCertSvg.svg"
import YouvahCertNew from "./images/certNew.png"
import Ellipse from "./svg/elipse.svg"
import RegisterNow from "./images/join_now.png"
import CourseScreenOverview from "./svg/screenOveriew.svg"
import RectangleYellow from "Assets/svg/newlyLaunchedBackground.svg"
import Timer from "Assets/svg/timer.svg"
import ScreenOverview from "Assets/svg/screenOveriew.svg"
import NewlyLaunched from "Assets/svg/newlyLaunchedBackground.svg"
import BusinessCenter from "Assets/svg/businessCenter.svg"
import FolderCopy from "Assets/svg/folder_copy.svg"
import Dvr from "Assets/svg/dvr.svg"
import Groups from "Assets/svg/groups.svg"
import Group from "Assets/svg/group.svg"
import PersonPin from "Assets/svg/person_pin.svg"
import Bookmark from "Assets/svg/Bookmark.svg"
import Partypops from "Assets/svg/PartPops.svg"
import Checkmark from "Assets/svg/CheckMark.svg"
import HandshakeSvg from "Assets/svg/handshake.svg"
import UniversitySvg from "Assets/svg/University.svg"
import DocumentSvg from "Assets/svg/document.svg"
import Card from "Assets/images/card.png"
import Hyperlinq from "./images/Company/hyperlink.png"
import LissunHero from "./images/srk-1480348930 1.png"
import CWPRO from './images/courses/CWPRO.png'
import CWPROGIF from './Gif/CWPRO.gif'
import Refer from './images/ReferNEarn.png'
import RohanSachar from "./images/12thPass/rohanSachar.png"
import SharadAnand from "./images/12thPass/sharadAnand.png"
import VincentThomas from "./images/12thPass/vincentThomas.png"

const Image: { [key: string]: string } = {
    logo: window.location.origin?.toLocaleLowerCase().includes(process.env.REACT_APP_ENVIRONMENT==="local"?'localhost-':'12thpass') ? logo12thPass : logo,
    logoYouvah:logo,
    logo12thPass,
    dashboard: dashboard,
    signinBackground: signinBackground,
    Google,
    backButton,
    WhatsApp,
    avatar1,
    avatar2,
    avatar3,
    avatar4,
    homePerson1,
    homePerson2,
    homePerson3,
    homePerson4,
    Blunt,
    CSB,
    Cultfit,
    Decathlon,
    Hyperlinq,
    NTL,
    Kifd,
    Uber,
    upGrad,
    Urban,
    Supertail,
    tapOn,
    Motiongility,
    Podpitara,
    Practo,
    Stage,
    Snapdeal,
    Handshake,
    dm,
    gdPro,
    pro,
    arrow,
    SMM,
    GD,
    VE,
    CW,
    PSY,
    WD,
    FM,
    More,
    LMS,
    Resume,
    Challenge,
    Internship,
    Choithram,
    Dps,
    Emerald,
    GdGoenka,
    Khaitan,
    Maurya,
    SSV,
    Sarvodaya,
    CertificatePro,
    Question,
    Study,
    Saving,
    Global,
    University,
    Card1,
    Link,
    Premium,
    Work,
    YouvahCard,
    ResumeGif,
    Varnitha,
    Avika,
    Ashvika,
    Divy,
    Japji,
    Milan,
    Pratyush,
    Radhika,
    Sanskriti,
    IIM,
    Facebook,
    Instagram,
    Linked,
    Sidebar_Toogle,
    Master_desktop,
    Tick,
    Cross,
    Awaits,
    Crown,
    AllprogramBanner,
    AllprogramBanner_phone,
    DM,
    GDPRO,
    Master_phone,
    lock,
    unlock,
    check,
    dot,
    trophy,
    Cry,
    Stars,
    Availability,
    Buffer,
    Pika,
    No_Course,
    Dude,
    EllipseB,
    Sun,
    Book,
    SunCloud,
    Colon,
    Briefcase,
    Bullseve,
    Check,
    Enroll,
    Master,
    Party,
    StarNew,
    Level,
    Watermelon,
    Edit,
    LogoStrokes,
    ChetanSirSign,
    BadgeInternship,
    CertificateTrophy,
    LogoWithoutName,
    WhatsAppGif,
    Drag,
    IG,
    FB,
    Snap,
    x,
    linkedin,
    Email,
    Phone,
    Dob,
    School,
    Link1,
    SocialMedia,
    Quality,
    Access,
    Impact,
    News1,
    News2,
    News3,
    SPOTL,
    SPOTR,
    Chetan,
    Raghav,
    Rohit,
    Devesh,
    Yatharth,
    Parv,
    Sakshi,
    Akshay,
    Anshul,
    Kuldeep,
    Shyam,
    Slide1,
    Slide2,
    Slide3,
    Slide4,
    Slide5,
    Slide6,
    Slide7,
    Thumbnail1,
    Thumbnail2,
    Hubspot,
    GA,
    Canva,
    Ahrefs,
    Hotjar,
    Hootsuite,
    Mailchimp,
    Buzzsumo,
    Semrush,
    BufferSvg,
    FitPro,
    Oic,
    TheYogaClub,
    YouvahCertPng,
    YouvahCertSvg,
    Ellipse,
    RegisterNow,
    CourseScreenOverview,
    RectangleYellow,
    Timer,
    ScreenOverview,
    NewlyLaunched,
    BusinessCenter,
    FolderCopy,
    Dvr,
    Groups,
    PersonPin,
    Bullseye,
    Bookmark,
    Partypops,
    Checkmark,
    StudySvg,
    HandshakeSvg,
    UniversitySvg,
    DocumentSvg,
    Card,
    Group,
    YouvahCertNew,
    JobCompany1,
    JobCompany2,
    JobCompany3,
    JobCompany4,
    JobCompany5,
    JobCompany6,
    JobCompany7,
    JobCompany8,
    LissunHero,
    CWPRO,
    CWPROGIF,
    Refer,
    RohanSachar,
    SharadAnand,
    VincentThomas
}
export default Image